/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-13 18:16:30
 * @Module: 动态详情
 */
 <template>
  <div class="DynamicState">
    <div class="text">{{data.content}}</div>
    <div class="box">
      <el-image v-for="(item,index) in imageUrlsList" style="width:200px;height:200px;" fit="cover" :src="item" :key="index" :preview-src-list="imageUrlsList">
      </el-image>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      data: {
        content: '',
        createdAt: '',
        imageUrls: ''
      }
    };
  },
  mounted () {
    this.getTrendDetail()
  },
  computed: {
    imageUrlsList () {

      if (this.data.imageUrls) {
        return this.data.imageUrls.split(',').map(item => "https://oss.mcn-open.com/" + item)
      } else {
        return []
      }
    }
  },
  methods: {
    async getTrendDetail () {
      const { status, data } = await this.$api.getTrendDetail(this.$route.params)
      if (status === 200) {
        this.data = data
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.DynamicState {
  padding: 20px;
  .text {
    white-space: pre-wrap;
    line-height: 1.5;
    font-size: 14px;
    color: #666;
  }
  .box {
    width: 620px;
    margin-top: 20px;
    .el-image:nth-child(3n - 1) {
      margin: 0 10px;
    }
    .el-image:nth-child(-n + 6) {
      margin-bottom: 10px;
    }
  }
}
</style>